<template>
  <a-table
    :columns="columns"
    :data-source="data()"
    :locale="{
      filterConfirm: 'Ok',
      filterReset: 'Restablecer',
      emptyText: 'Sin datos',
    }"
    :scroll="{ x: 1300 }"
  >
    <template #rol="{ record }">
      <p>
        {{
          record.rol === "ally"
            ? "Aliado"
            : record.rol === "delivery"
            ? "Motorizado"
            : "No válido"
        }}
      </p>
    </template>
    <template #accepted="{ record }">
      <a-switch :checked="record.accepted" @change="changeAccepted(record)" />
    </template>
    <template #bloqued="{ record }">
      <a-switch :checked="record.bloqued" @change="changeBloqued(record)" />
    </template>
    <template #block_payment="{ record }">
      <a-switch
        :checked="record.block_payment"
        @change="changeBlockPayment(record)"
      />
    </template>
    <template #actions="{ record }">
      <router-link :to="'/admin/super/requests/' + record.id">
        <a-button type="primary">Editar</a-button>
      </router-link>
      <a-popconfirm
        title="Quieres eliminar este usuario?"
        ok-text="Si"
        cancel-text="No"
        @confirm="remove(record)"
      >
        <a-button type="danger">Eliminar</a-button>
      </a-popconfirm>
    </template>
  </a-table>
</template>

<script>
import { useStore } from "vuex";
import { getRequests } from "../../../router/auth_guard";
const columns = [
  {
    title: "Id",
    dataIndex: "id",
  },
  {
    title: "Nombre",
    dataIndex: "username",
    defaultSortOrder: "descend",
    sorter: (a, b) =>
      a.username > b.username ? 1 : a.username < b.username ? -1 : 0,
  },
  {
    title: "Teléfono",
    dataIndex: "phone",
    defaultSortOrder: "descend",
    sorter: (a, b) => (a.phone > b.phone ? 1 : a.phone < b.phone ? -1 : 0),
  },
  {
    title: "Rol",
    dataIndex: "rol",
    defaultSortOrder: "descend",
    slots: { customRender: "rol" },
    filters: [
      {
        text: "Aliado",
        value: "ally",
      },
      {
        text: "Motorizado",
        value: "delivery",
      },
    ],
    onFilter: (value, record) => record.rol === value,
    sorter: (a, b) => (a.rol > b.rol ? 1 : a.rol < b.rol ? -1 : 0),
  },
  {
    title: "Aceptado",
    key: "accepted",
    slots: { customRender: "accepted" },
    filters: [
      {
        text: "Aceptados",
        value: true,
      },
      {
        text: "No aceptado",
        value: false,
      },
    ],
    onFilter: (value, record) => {
      const accepted = record.accepted ?? false;
      return accepted === value;
    },
  },
  {
    title: "Bloqueo por pago",
    key: "block_payment",
    slots: { customRender: "block_payment" },
    filters: [
      {
        text: "Bloqueados",
        value: true,
      },
      {
        text: "Sin bloquear",
        value: false,
      },
    ],
    onFilter: (value, record) => {
      const bloqued = record.block_payment ?? false;
      return bloqued === value;
    },
  },
  {
    title: "Bloqueado",
    key: "bloqued",
    slots: { customRender: "bloqued" },
    filters: [
      {
        text: "Bloqueados",
        value: true,
      },
      {
        text: "Sin bloquear",
        value: false,
      },
    ],
    onFilter: (value, record) => {
      const bloqued = record.bloqued ?? false;
      return bloqued === value;
    },
  },
  {
    title: "Acciones",
    key: "actions",
    slots: { customRender: "actions" },
  },
];

export default {
  setup() {
    const store = useStore();
    function data() {
      return store.getters.requests;
    }
    function changeAccepted(data) {
      const accepted = data.accepted ?? false;
      store.dispatch("changeAccepted", {
        accepted: !accepted,
        id: data.id,
      });
    }
    function changeBloqued(data) {
      const bloqued = data.bloqued ?? false;
      store.dispatch("changeBloqued", {
        bloqued: !bloqued,
        id: data.id,
      });
    }
    function changeBlockPayment(data) {
      const block_payment = data.block_payment ?? false;
      store.dispatch("changeBlockPayment", {
        block_payment: !block_payment,
        id: data.id,
      });
    }
    function remove(data) {
      store.dispatch("removeUser", data.id);
    }
    return {
      data,
      changeAccepted,
      changeBloqued,
      changeBlockPayment,
      remove,
      columns,
    };
  },
  beforeRouteEnter: getRequests,
};
</script>

<style>
.ant-table-body {
  overflow-x: auto !important;
}
</style>